import React from "react";

type YourLevelBadgeProps = {
  status: string;
  Icon: React.ElementType;
  textColor: string;
  borderColor: string;
};

export function YourLevelBadge({
  status,
  Icon,
  textColor,
  borderColor,
}: YourLevelBadgeProps) {
  return (
    <div
      className={`flex items-center rounded-2xl border bg-background p-4 w-full max-w-sm ${textColor}`}
    >
      <div
        className={`flex h-10 w-10 justify-center rounded-full border-2 ${borderColor} bg-background p-0.5`}
      >
        <Icon />
      </div>
      <div className="flex flex-col items-start text-left leading-none ml-4">
        <div className="text-xs font-normal uppercase">Your level</div>
        <div className="font-medium text-lg">{status}</div>
      </div>
      <div className="ml-auto text-4xl">18</div>
    </div>
  );
}
