import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { WHITE, YELLOW, TEAL } from "#constants";

export function ConventionalRangeBar() {
  const userValue = 18;
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(800);

  const updateWidth = () => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      d3.select(ref.current).selectAll("svg").remove();

      const twColors = {
        belowOptimal: YELLOW,
        normal: TEAL,
        aboveOptimal: YELLOW,
        white: WHITE,
      };

      const ranges = [
        { start: 1, end: 10, color: twColors.belowOptimal, label: "" },
        { start: 10, end: 90, color: twColors.normal, label: "10" },
        { start: 90, end: 100, color: twColors.aboveOptimal, label: "90" },
      ];

      const svgHeight = 65;
      const svg = d3
        .select(ref.current)
        .append("svg")
        .attr("width", width)
        .attr("height", svgHeight);

      const xScale = d3.scaleLinear().domain([1, 100]).range([0, width]);

      const gap = 2;
      const rectHeight = 6;
      const rectY = (svgHeight - rectHeight) / 2;

      svg
        .selectAll("rect")
        .data(ranges)
        .enter()
        .append("rect")
        .attr("x", (d) => xScale(d.start) + gap)
        .attr("y", rectY)
        .attr("width", (d) => xScale(d.end) - xScale(d.start) - gap * 2)
        .attr("height", rectHeight)
        .attr("fill", (d) => d.color)
        .attr("rx", rectHeight / 2)
        .attr("ry", rectHeight / 2);

      // User value circle initialization
      const circleRadius = 10;
      const initialX = Math.max(circleRadius, xScale(1));
      const circle = svg
        .append("circle")
        .attr("cx", initialX)
        .attr("cy", svgHeight / 2)
        .attr("r", 10)
        .attr("fill", twColors.white)
        .attr("stroke", twColors.normal)
        .attr("stroke-width", 2);

      // Animate the circle to slide to the user's value
      circle.transition().duration(1500).attr("cx", xScale(userValue));

      // Animating label above the circle
      const label = svg
        .append("text")
        .attr("x", initialX)
        .attr("y", rectY - 12)
        .attr("fill", twColors.normal)
        .attr("text-anchor", "middle")
        .attr("font-size", "20px")
        .attr("font-weight", "bold")
        .text("0");

      // Animate label along with the circle
      label
        .transition()
        .duration(1500)
        .tween("text", function () {
          const that = d3.select(this);
          const i = d3.interpolateNumber(0, userValue);
          return function (t) {
            that.text(Math.round(i(t))); // Update text based on interpolation
          };
        })
        .attr("x", xScale(userValue));

      // Labels below the rectangles
      svg
        .selectAll("text")
        .data(ranges)
        .enter()
        .append("text")
        .attr("x", (d) => xScale(d.start) - 5)
        .attr("y", svgHeight - 10)
        .text((d) => d.label ?? "")
        .attr("text-anchor", "start")
        .attr("font-size", "10px")
        .attr("fill", "#444");
    }
  }, [width, userValue]);

  return <div ref={ref} className="w-full"></div>;
}
