import { Check, Minus } from "#components/icons/index.js";
import { SaanaRangeBar } from "#components/visualisations/saanaRangeBar";
import { ConventionalRangeBar } from "#components/visualisations/conventionalRangeBar";
import { YourLevelBadge } from "#components/visualisations/yourLevelBadge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "#components/ui";

export function Ranges() {
  return (
    <>
      <Tabs defaultValue="saana" className="text-center">
        <TabsList className="bg-transparent border-stone-800">
          <TabsTrigger value="saana">saanā range</TabsTrigger>
          <TabsTrigger value="conventional">Conventional range</TabsTrigger>
        </TabsList>
        <TabsContent value="saana">
          <div className="mb-8 mt-6 text-center">
            <div className="mx-auto max-w-prose text-balance">
              Our ranges are based on scientific evidence and the latest
              research. They are linked to better heath and longevity.
            </div>
          </div>

          <div className="flex flex-col gap-8">
            <div className="w-full">
              <SaanaRangeBar />
            </div>
            <div className="flex justify-center items-center">
              <YourLevelBadge
                status="Needs attention"
                Icon={Minus}
                textColor="text-[#F2BA11]"
                borderColor="border-[#F2BA11]"
              />
            </div>
          </div>
        </TabsContent>

        <TabsContent value="conventional">
          <div className="mb-8 mt-6 text-center">
            <div className="text-xl font-medium">
              <span className="text-[#008483]">Normal</span>
              {` doesn't `}mean healthy
            </div>
            <div className="mx-auto max-w-prose text-balance text-sm text-neutral-800">
              Even if your blood test says {` you're `} normal, it might not
              mean
              {` you're `} at your healthiest. If you truly want to optimise
              your health, saanā ranges can help.
            </div>
          </div>

          <div className="flex flex-col gap-8">
            <div className="w-full">
              <ConventionalRangeBar />
            </div>
            <div className="flex justify-center items-center">
              <YourLevelBadge
                status="Normal"
                Icon={Check}
                textColor="text-[#008483]"
                borderColor="border-[#008483]"
              />
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
}
