type IconProps = {
  fill?: string
  width?: string
  height?: string
}

export const Minus = ({ ...props }: IconProps) => {
  const fill = props.fill || 'currentColor'
  const width = props.width || '100%'
  const height = props.height || '100%'

  return (
    <svg
      height={height}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <line x1='5' y1='12' x2='19' y2='12'></line>
    </svg>
  )
}
